import React from "react";
import PauseIcon from '@material-ui/icons/Pause';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

interface PlayButtonProps {
    playing: boolean;
    playOrPause: () => void;
}

interface PlayButtonState {}

class PlayButton extends React.Component<PlayButtonProps, PlayButtonState> {
    
    constructor(props: PlayButtonProps) {
        super(props);
    }

    render() {
        return (
            <div>
                <IconButton color="inherit" onClick={() => {this.props.playOrPause()}}>
                    {this.props.playing &&
                        <PauseIcon />
                    }
                    {!this.props.playing &&
                        <PlayArrowIcon />
                    }
                </IconButton>
            </div>
        )
    }
}

export default PlayButton