import React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';


interface OptionsProps {
    handleIncludeEdited: () => void;
    handleIncludeNegative: () => void;
    includeEdited: boolean;
    includeNegative: boolean;
}

interface OptionsState {}

class Options extends React.Component<OptionsProps, OptionsState> {
    constructor(props: OptionsProps) {
        super(props);
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.includeEdited}
                                    onChange={this.props.handleIncludeEdited}
                                    name="includeEdited"
                                    color="primary"/>
                            }
                            label="Incl. edited comments"/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.includeNegative}
                                    onChange={this.props.handleIncludeNegative}
                                    name="includeNegative"
                                    color="primary"/>
                            }
                            label="Incl. negative comments"/>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default Options