import React from "react";
import clsx from 'clsx';
import Comment from "../Comment/Comment"
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import { withStyles} from "@material-ui/core/styles";
import Control from "../Control";
import Divider from '@material-ui/core/Divider';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import List from '@material-ui/core/List';
import LinearProgress from '@material-ui/core/LinearProgress';
import Options from "../Options";
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import Home from "../Home"
import { ThemeProvider } from '@material-ui/core/styles';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import CssBaseline from "@material-ui/core/CssBaseline";
import { appBarStyleLight, appBarStyleDark, styles, darkTheme, lightTheme } from './threadStyles';
import { ThreadProps, ThreadState } from './index';
import { isMobile } from 'react-device-detect';

const snoowrap = require('snoowrap');
let commentsToDisplay: any = [];

class Thread extends React.Component<ThreadProps, ThreadState> {
    
    constructor(props: ThreadProps) {
        super(props);
        this.state = {
            commentsInRange: [],
            commentsToGet: 'empty',
            commentsToGetKey: 'empty',
            start_utc: 0,
            comments_received_until_utc: -1,
            time_utc: 0,
            timeout_hit: -1,
            playing: true,
            topLevelCommentsRequestOut: false,
            loading: true,
            submissionId: '',
            code: '',
            includeEdited: false,
            includeNegative: true,
            lastTime: 0,
            reddit: null,
            dark: true,
            originalMoreChildren: null,
            open: true,
            commentCount: 0,
            submission: null,
            comments: null,
            masterComments: [],
        };
        this.customTimeout = this.customTimeout.bind(this);
        this.isDisplayable = this.isDisplayable.bind(this);
        this.playOrPause = this.playOrPause.bind(this);
        this.timeJump = this.timeJump.bind(this);
        this.handleIncludeEdited = this.handleIncludeEdited.bind(this);
        this.handleIncludeNegative = this.handleIncludeNegative.bind(this);
        this.commentIdBinarySearch = this.commentIdBinarySearch.bind(this);
        this.handleDarkToggle = this.handleDarkToggle.bind(this);
        this.goHome = this.goHome.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
    }

    handleDrawerOpen() {
        this.setState({
            open: true
        })
    };
    
    handleDrawerClose() { 
        this.setState({
            open: false
        })
    };
    

    async timeJump(x: number) {
        const newUTC: number = this.state.time_utc + x;
        this.setState({
            time_utc: newUTC
        });
        if(x > 240 || x < -100) {
            if(this.state.originalMoreChildren !== null) {
                this.setState({
                    loading: true
                })
                await this.commentIdBinarySearch(this.state.originalMoreChildren, newUTC - 200, 0).then((slice: number) => {
                    this.state.comments._more.children = this.state.originalMoreChildren.slice(slice);
                    this.setState({
                        loading: false
                    })
                });
            }
        }
    }

    handleDarkToggle() {
        this.setState(prevState => ({
            dark: !prevState.dark
        }))
        window.localStorage.setItem('dark', this.state.dark.toString())
    }

    playOrPause() {
        this.setState(prevState => ({
            playing: !prevState.playing
        }))
    }

    initiateThread(r: any) {
        r.getSubmission(this.state.submissionId + '/?sort=old').fetch().then((sub: any) => {
            this.setState({
                submission: sub
            })
            this.setState({
                time_utc: this.state.submission.created_utc,
                loading: false
            })
            this.setState({
                comments: this.state.submission.comments
            }) 
            if(this.state.comments._more !== null) {
                this.state.comments._more.children = this.state.comments._more.children.sort();
                this.setState({
                    originalMoreChildren: this.state.comments._more.children
                })
            }
            for(let comment of this.state.comments) {
                if(comment.parent_id === 't3_' + this.state.submissionId) {
                    this.setState(prevState => ({
                        commentCount: prevState.commentCount
                    }))
                    const commentProps = {
                        body: comment.body,
                        created_utc: comment.created_utc,
                        author: comment.author.name,
                        score: comment.score,
                        count: this.state.commentCount,
                        edited: comment.edited,
                        id: comment.id
                    }
                    this.state.masterComments.unshift(<Comment {...commentProps}/>);
                }
            } 
        }).catch((err: any) => {
            console.log(err.message);
        });
    }

    async getCommentsNoPython() {
        if(this.state.submission == null || this.state.comments == null) {

            let accessToken: string | null = window.localStorage.getItem('accessToken');
            let clientId: string | null = window.localStorage.getItem('clientId');
            let refreshToken: string | null = window.localStorage.getItem('refreshToken');

            if(accessToken != null && clientId != null && refreshToken != null) {
                accessToken = accessToken.replace('/', '');
                accessToken = accessToken.slice(1, -1);
                refreshToken = refreshToken.replace('/', '');
                refreshToken = refreshToken.slice(1, -1);
                console.log(accessToken);
                console.log(refreshToken);
                const r = new snoowrap({
                    userAgent: 'reReddit by sharkpony',
                    clientId: 'cn1cJCgJbsqwXQ',
                    clientSecret: '',
                    accessToken: accessToken,
                    refreshToken: refreshToken
                })
                this.initiateThread(r);
                this.setState({
                    reddit: r
                })
            } else {

                snoowrap.fromAuthCode({
                    code: this.state.code,
                    userAgent: 'reReddit by sharkpony',
                    clientId: 'cn1cJCgJbsqwXQ',
                    redirectUri: 'https://rereddit.xyz/go'
                }).then((r: any) => {
                    window.localStorage.setItem('accessToken', JSON.stringify(r.accessToken));
                    window.localStorage.setItem('clientId', JSON.stringify(r.clientId));
                    window.localStorage.setItem('refreshToken', JSON.stringify(r.refreshToken));
                    this.initiateThread(r);
                    this.setState({
                        reddit: r
                    })
                }).catch((err: any) => {
                    console.log(err);
                    console.log(err.message);
                })
            }
            
        } else {
            this.state.comments.fetchMore({amount: 500, skipReplies: true, append: false}).then((com: any) => {
                this.setState({
                    comments: com
                })
                for(let comment of this.state.comments) {
                    if(comment.parent_id === 't3_' + this.state.submissionId) {
                        this.setState(prevState => ({
                            commentCount: prevState.commentCount
                        }))
                        const commentProps = {
                            body: comment.body,
                            created_utc: comment.created_utc,
                            author: comment.author.name,
                            score: comment.score,
                            count: this.state.commentCount,
                            edited: comment.edited,
                            id: comment.id
                        }
                        this.state.masterComments.unshift(<Comment {...commentProps}/>);
                    } 
                }
            });
        }
    }
 
    async commentIdBinarySearch(commentIds: Array<string>, utc: number, placeTracker: number): Promise<number> {
        let id: string;

        if(commentIds.length == 1) {
            await this.state.reddit.getComment(commentIds[0]).fetch().then((comment: any) => {
                console.log(comment.created_utc);
            });
            console.log(placeTracker);
            return placeTracker;
            /*
            if(this.state.reddit.getComment(commentIds[0]) == utc) {
                return 0;
            } else {
                return -1;
            }
            */
        } else {
            id = commentIds[Math.ceil(commentIds.length / 2)];
        }
        
        let thisUtc: number = -1;
        await this.state.reddit.getComment(id).fetch().then((comment: any) => {
            thisUtc = comment.created_utc;
        })
        placeTracker = placeTracker + Math.ceil(commentIds.length / 2);
        if(thisUtc == utc) {
            console.log(placeTracker);
            return placeTracker;
        } else if (thisUtc > utc) {
            placeTracker = placeTracker - Math.ceil(commentIds.length / 2);
            return this.commentIdBinarySearch(
                commentIds.slice(0, Math.ceil(commentIds.length / 2)),
                utc, 
                placeTracker);
        } else {
            return this.commentIdBinarySearch(
                commentIds.slice(Math.ceil(commentIds.length / 2), commentIds.length), 
                utc, 
                placeTracker);

        }
    }

    customTimeout() {
        const diff: number = (new Date().getTime() - this.state.lastTime) / 1000
        if(this.state.playing) {
            this.setState(prevState => ({
                time_utc: prevState.time_utc + diff,
                timeout_hit: prevState.timeout_hit + 1
            }));
            if(this.state.timeout_hit % 10 == 0) {
                this.getCommentsNoPython();
            }
        }
        this.setState({
            lastTime: new Date().getTime()
        })
        setTimeout(this.customTimeout, 1000)
    }

    n = setTimeout(() => {
        this.setState({
            lastTime: new Date().getTime()
        })
        this.customTimeout();
    }, 1000);

    componentDidMount() {
        const pathname: Array<string> = window.location.pathname.split('/');
        this.setState({
            submissionId: pathname[pathname.length - 2],
            code: pathname[pathname.length - 1]
        })
        const storageDark: boolean = (window.localStorage.getItem('dark') === 'true');

        this.setState({
            dark: storageDark
        })
    }

    isDisplayable(element: any, index: any, array: any) {
        if (!this.state.includeEdited) {
            if (element.props.edited) {
                return false;
            }
        }
        if (!this.state.includeNegative) {
            if (element.props.score < 0) {
                return false;
            }
        }
        return (element.props.created_utc < this.state.time_utc); 
    }

    handleIncludeEdited() {
        this.setState(prevState => ({
            includeEdited: !prevState.includeEdited
        }));
    }

    handleIncludeNegative() {
        this.setState(prevState => ({
            includeNegative: !prevState.includeNegative
        }));
    }
    //<a href="https://www.buymeacoffee.com/Kelso" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" style="height: 60px !important;width: 217px !important;" ></a>

    goHome() {
        console.log('yer')
        window.history.replaceState('','', '/go');
        window.location.reload();
    }

    render() {
        
        const { classes } = this.props;
        /*
        let trackingComment: any = null;
        if(commentsToDisplay.length !== 0) {
            trackingComment = commentsToDisplay[0];
        } 
        */
        if (this.state.masterComments) {
            commentsToDisplay = this.state.masterComments.filter(this.isDisplayable).slice(0, 300)
            //commentsToDisplay = this.state.masterComments.filter(this.isDisplayable);
            //commentsToDisplay = commentsToDisplay.slice(0, 300);
        }
        /*
        if(trackingComment !== null) {
            const newTrackingIndex: number = commentsToDisplay.indexOf(trackingComment);
            console.log('scrolling by ' + newTrackingIndex + ' times ' + 50 + ' pixels');
            if(newTrackingIndex > 10) {
                let height: number = 0;
                for(let i = 0; i < newTrackingIndex, i++) {
                    height = height + 75
                }
                window.scrollBy(newTrackingIndex, 50);
            }
        }
        */
        return (
            <div className={classes.root}>
                <ThemeProvider theme={this.state.dark ? darkTheme : lightTheme}>
                    <CssBaseline/>
                    <AppBar 
                        style={this.state.dark? appBarStyleDark: appBarStyleLight}
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: this.state.open,
                          })}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, this.state.open && classes.hide)}>
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap onClick={this.goHome}>
                                reReddit
                            </Typography>
                            <IconButton
                                color="inherit"
                                aria-label="toggle dark"
                                onClick={this.handleDarkToggle}
                                className={classes.toolbarButtons}>
                                {this.state.dark && <Brightness7Icon />}
                                {!this.state.dark && <Brightness4Icon />}
                            </IconButton>
                            {!isMobile && <Button
                                href="https://www.buymeacoffee.com/Kelso"
                                target="_blank"
                                variant="contained"
                                color="inherit"
                                endIcon={<LocalCafeIcon />}>
                                Support this project
                            </Button>}
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={this.state.open}
                        classes={this.state.dark? {
                            paper: classes.darkDrawerPaper
                        } : {
                            paper: classes.drawerPaper,
                        }}>
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={this.handleDrawerClose}>
                              <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <List>
                            <Control 
                                timeJump={this.timeJump}
                                playOrPause={this.playOrPause}
                                playing={this.state.playing}
                                time_utc={this.state.time_utc}/>
                            <Divider/>
                            <Options
                                handleIncludeEdited={this.handleIncludeEdited}
                                handleIncludeNegative={this.handleIncludeNegative}
                                includeEdited={this.state.includeEdited}
                                includeNegative={this.state.includeNegative}/>
                            {isMobile && <Button
                                href="https://www.buymeacoffee.com/Kelso"
                                target="_blank"
                                variant="contained"
                                color="inherit"
                                endIcon={<LocalCafeIcon />}>
                                Support this project
                            </Button>}
                        </List>
                    </Drawer>
                    <main className={clsx(classes.content, {
                        [classes.contentShift]: this.state.open,
                    })}>
                        <div className={classes.toolbar} />
                        <Container>
                            {this.state.loading && 
                                <LinearProgress />
                            }
                            <Grid container wrap="nowrap" spacing={2} direction="column">
                                {commentsToDisplay && commentsToDisplay.map((comment: any) => {
                                    return (
                                        <React.Fragment key={comment.id}>
                                            <Grid item>
                                                {comment}
                                            </Grid>
                                        </React.Fragment>
                                    )
                                })}
                            </Grid>
                        </Container>
                    </main>
                </ThemeProvider>
            </div>
        )
    }    
}

export default withStyles(styles)(Thread)