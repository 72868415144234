import React from "react";
import PlayButton from './PlayButton'
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Forward30Icon from '@material-ui/icons/Forward30';
import Replay30Icon from '@material-ui/icons/Replay30';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { isMobile } from 'react-device-detect';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';

import {
    MuiPickersUtilsProvider,
    DateTimePicker,
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';

interface ControlProps {
    playing: boolean;
    playOrPause: () => void;
    timeJump:(x: number) => void;
    time_utc: number;
}

interface ControlState {
    inputValue: number
}

class Control extends React.Component<ControlProps, ControlState> {

    constructor(props: ControlProps) {
        super(props);
        this.state = {
            inputValue: 0
        }
    }

    handleDateChange = (date: any) => {
        const firstUTC: number = Math.floor(date.getTime() / 1000)
        const secondUTC: number = this.props.time_utc;
        console.log("should be seeking " + firstUTC);
        this.props.timeJump(firstUTC - secondUTC);
    };

    

    render() {

        moment().format(); 
        var day = moment.unix(this.props.time_utc);
        
        return (
            <div>
                <Grid container direction="row" justify="space-evenly" alignItems="center">
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                value={day}
                                onChange={this.handleDateChange}/>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="space-evenly" alignItems="center">
                    <Grid item>
                        <IconButton color="inherit" onClick={() => {this.props.timeJump(-30)}}>
                            <Replay30Icon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <PlayButton 
                            playOrPause={this.props.playOrPause}
                            playing={this.props.playing}/>
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" onClick={() => {this.props.timeJump(30)}}>
                            <Forward30Icon />
                        </IconButton>
                    </Grid>
                    
                </Grid>
            </div>
        )
    }
}

export default Control