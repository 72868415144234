import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home"
import Thread from "./components/Thread/Thread"
import Login from "./components/Login"


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={() => <Login />} />
          <Route path="/go/" exact component={() => <Home/>} />
          <Route path="/thread/*" exact component={() => <Thread />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
