import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { createStyles, withStyles, WithStyles} from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Control from "./Control";
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import List from '@material-ui/core/List';
import Options from "./Options";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { blueGrey, red, grey } from '@material-ui/core/colors';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import CssBaseline from "@material-ui/core/CssBaseline";
import { isMobile } from 'react-device-detect';

const drawerWidth = 240;
const snoowrap = require('snoowrap');

const lightTheme = createMuiTheme({
    palette: {
        primary: {
            main: blueGrey[200]
        },
        secondary: {
            main: red[500]
        },
      },
});

const darkTheme = createMuiTheme({
    palette: {
        type: "dark"
    }
})

const appBarStyleLight: CSSProperties = {
    background: '#b0bec5'
}

const appBarStyleDark: CSSProperties = {
    background: '#616161'
}

const styles = (theme: any) => createStyles({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
    toolbar: theme.mixins.toolbar,
  });

interface MyProps extends WithStyles<typeof styles> {}
interface MyState {
    dark: boolean
}


let open = false;

const handleDrawerOpen = () => {
    open = true;
};

const handleDrawerClose = () => {
    open = false;
};

class Login extends React.Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            dark: false
        }
        this.handleLogin = this.handleLogin.bind(this);
        this.handleDarkToggle = this.handleDarkToggle.bind(this);
    }

    handleDarkToggle() {
        this.setState(prevState => ({
            dark: !prevState.dark
        }))
        window.localStorage.setItem('dark', this.state.dark.toString())
    }

    handleLogin() {
        let authenticationUrl: Location;
        if(isMobile) {
            authenticationUrl = snoowrap.getAuthUrl({
                clientId: 'cn1cJCgJbsqwXQ',
                scope: ['read'],
                redirectUri: 'https://rereddit.xyz/go',
                permanent: true,
                mobile: true,
                state: 'fe211bebc52eb3da9bef8db6e63104d3' // a random string, this could be validated when the user is redirected back
            });
        } else {
            authenticationUrl = snoowrap.getAuthUrl({
                clientId: 'cn1cJCgJbsqwXQ',
                scope: ['read'],
                redirectUri: 'https://rereddit.xyz/go',
                permanent: true,
                mobile: false,
                state: 'fe211bebc52eb3da9bef8db6e63104d3' // a random string, this could be validated when the user is redirected back
            });
        }
        window.location = authenticationUrl;
    }

    componentDidMount() {
        const accessToken: string | null = window.localStorage.getItem('accessToken');
        const clientId: string | null = window.localStorage.getItem('clientId');
        const refreshToken: string | null = window.localStorage.getItem('refreshToken');

        const storageDark: boolean = (window.localStorage.getItem('dark') === 'true');

        this.setState({
            dark: storageDark
        })

        if(accessToken != null && clientId != null && refreshToken != null) {
            window.history.replaceState('','', '/go');
            window.location.reload();
        }
    }

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <ThemeProvider theme={this.state.dark ? darkTheme : lightTheme}>
                    <CssBaseline/>
                    <AppBar 
                        style={this.state.dark? appBarStyleDark: appBarStyleLight}
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: open,
                            })}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="toggle dark"
                                onClick={this.handleDarkToggle}
                                className={classes.toolbarButtons}>
                                {this.state.dark && <Brightness7Icon />}
                                {!this.state.dark && <Brightness4Icon />}
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                          paper: classes.drawerPaper,
                        }}>
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={handleDrawerClose}>
                              <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <List>

                        </List>
                    </Drawer>
                    <main className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })}>
                        <div className={classes.toolbar} />
                            <Grid container wrap="nowrap" direction="column" justify="center" alignItems="center">
                                <Grid item>
                                    <Grid container wrap="nowrap" direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                        <Grid item>
                                            <Typography variant="h2">
                                                reReddit
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3">
                                                Replay Reddit threads, anytime
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" color="default" onClick={this.handleLogin}>
                                                Authenticate with reddit
                                            </Button>
                                        </Grid>  
                                    </Grid>
                                </Grid>
                            </Grid>
                    </main>
                    
                </ThemeProvider>

                
            </div>
        )
    }
}

export default withStyles(styles)(Login);
