import React from "react";
import { withRouter } from "react-router-dom";
import Card from '@material-ui/core/Card';
import moment from 'moment';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { CommentProps, CommentState } from './index';

class Comment extends React.Component<CommentProps, CommentState> {
    constructor(props: CommentProps) {
        super(props);
    }

    render() {
        return (
            <Box width={1}>
                <Card>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <CardContent>
                                <Typography display="inline" variant="h6" component="h6">
                                    <Box display="inline" fontWeight="fontWeightLight">
                                        {this.props.author}
                                    </Box>
                                </Typography>
                                <Typography display="inline" variant="h6" component="h6">
                                    <Box display="inline" fontWeight="fontWeightLight" >
                                        {'   ' + this.props.score + ' points ' + moment.unix(Number.parseInt(this.props.created_utc)).toString()}
                                    </Box>
                                </Typography>
                                <Typography variant="h6" component="h6">
                                    <Box fontWeight="fontWeightRegular">
                                        {this.props.body}
                                    </Box>
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        )
    }
}

export default withRouter(Comment)