import { CSSProperties } from "react";
import { createStyles } from "@material-ui/core/styles";
import { createMuiTheme } from '@material-ui/core/styles';
import { blueGrey, red, grey } from '@material-ui/core/colors';

export const lightTheme = createMuiTheme({
    palette: {
        primary: {
            main: blueGrey[200]
        },
        secondary: {
            main: red[500]
        }
      },
});

export const darkTheme = createMuiTheme({
    palette: {
        type: "dark",
        text: {
            primary: grey[50]
        }
    },
    overrides: {
        MuiIconButton: {
            colorInherit: {
                color: grey[50]
            }
        },
        MuiButton: {
            colorInherit: {
                color: '#616161'
            }
        },
        MuiFormControlLabel: {
            label: {
                color: grey[50]
            }
        }
    }
})

export const appBarStyleLight: CSSProperties = {
    background: '#b0bec5'
}

export const appBarStyleDark: CSSProperties = {
    background: '#616161'
}

const drawerWidth = 240;

export const styles = (theme: any) => createStyles({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    darkDrawerPaper: {
        width: drawerWidth,
        color: grey[700]
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    toolbar: theme.mixins.toolbar,
  });